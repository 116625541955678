import React, { useEffect, useState } from 'react';
import './AllClients.css';
import { useNavigate } from 'react-router-dom';
import { getClientsForall } from '../../services/api';
import { FaArrowRight } from 'react-icons/fa';

const AllClients = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [displayedCount, setDisplayedCount] = useState(0);
    const [money, setMoney] = useState(0);
    const [animateCount, setAnimateCount] = useState(false);
    const [kindNum, setKindNum] = useState(0);
    const [kindNumOver15, setKindNumOver15] = useState(0);

    const [kundeFilter, setKundeFilter] = useState('');
    const [nachnameFilter, setNachnameFilter] = useState('');
    const [monthFilter, setMonthFilter] = useState(() => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    });

    const [sortField, setSortField] = useState('submite_date'); // Default sort field
    const [sortOrder, setSortOrder] = useState('asc'); // Default sort order

    const navigate = useNavigate();

    const getAge = (birthdate) => {
        if (!birthdate) return 0;
        const today = new Date();
        const birthDate = new Date(birthdate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth();
        if (month < birthDate.getMonth() || (month === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const getChildInfo = (client) => {
        const childrenArray = Array.isArray(client.children) ? client.children : [];
        const totalChildren = childrenArray.filter(child => child?.birthdate).length;
        const childrenOver15 = childrenArray.filter(child => child?.birthdate && getAge(child.birthdate) > 15);
        return {
            totalChildren,
            childrenOver15: childrenOver15.length
        };
    };
    const increaseAmount = (num) => {
        let startCount = 0;
        const endCount = num;
    
        if (endCount > 0) {
            const duration = 1000; // Animation duration in milliseconds
            const increment = Math.ceil(endCount / (duration / 1)); // Adjust increment based on duration
    
            const interval = setInterval(() => {
                startCount += increment;
                if (startCount >= endCount) {
                    startCount = endCount;
                    clearInterval(interval); // Stop when we reach the final count
                }
                setDisplayedCount(startCount); // Update the displayed count
                setMoney(startCount * 20); // Update money count based on filtered Aufträge
                setAnimateCount(true); // Trigger animation
                setTimeout(() => setAnimateCount(false), 1000); // Reset animation state
            }, 100); // Run every 100 milliseconds
    
            return () => clearInterval(interval); // Clean up on unmount
        } else {
            setMoney(startCount * 20);
            setDisplayedCount(startCount);
        }
    };
    
    useEffect(() => {
        const filteredClients = getFilteredAntrags(clients);
    
        // Calculate the total amount: 1 for each client, plus 1 for each child older than 15
        let totalAmount = 0;
    
        filteredClients.forEach(client => {
            // Add 1 for each client
            totalAmount += 1;
    
            // Add 1 for each child older than 15
            const { childrenOver15 } = getChildInfo(client); // Get the count of children over 15
            totalAmount += childrenOver15; // Add children over 15 count to totalAmount
        });
    
        // Animate the amount
        increaseAmount(totalAmount); // Call the animation function with the calculated totalAmount
    }, [clients, monthFilter, kundeFilter, nachnameFilter]);
    
    
    useEffect(() => {
        const totalChildren = clients.reduce((total, client) => total + getChildInfo(client).totalChildren, 0);
        const totalChildrenOver15 = clients.reduce((total, client) => total + getChildInfo(client).childrenOver15, 0);
        setKindNum(totalChildren);
        setKindNumOver15(totalChildrenOver15);
    }, [clients]);

    useEffect(() => {
        getClients();
    }, []);

    const getClients = async () => {
        setLoading(true);
        try {
            const response = await getClientsForall();
            setClients(response.data);
        } catch (err) {
            setError('Failed to fetch clients. Please check your permissions.');
        } finally {
            setLoading(false);
        }
    };

    const getMonthRange = (month) => {
        const date = new Date(month);
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return { startDate, endDate };
    };

    const getFilteredAntrags = (clients) => {
        return clients.filter(client => {
            const matchesKunde = kundeFilter === '' || (client.vornname && client.vornname.toLowerCase().includes(kundeFilter.toLowerCase()));
            const matchesNachname = nachnameFilter === '' || (client.nachname && client.nachname.toLowerCase().includes(nachnameFilter.toLowerCase()));
            const { startDate, endDate } = getMonthRange(monthFilter);
            const sentDate = new Date(client.submite_date || "");
            const matchesDateRange = !monthFilter || (sentDate >= startDate && sentDate <= endDate);
            return matchesKunde && matchesNachname && matchesDateRange;
        });
    };

    const toggleSortOrder = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    const sortAntrags = (clients) => {
        const sortedAntrags = [...clients].sort((a, b) => {
            const dateA = new Date(a[sortField] || "");
            const dateB = new Date(b[sortField] || "");
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
        return sortedAntrags;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const filteredClients = getFilteredAntrags(clients);
    const sortedClients = sortAntrags(filteredClients);

    return (
        <div className='famielien-form-container'>
            <div className="my-antrags-container">
                <h2>Alle Kunden</h2>
                {clients.length === 0 ? (
                    <p>No clients found.</p>
                ) : (
                    
                    <div>
                        <div className="counter-wrapper">
    {/* Display the animated Aufträge count */}
    <p className={`digital-counter ${animateCount ? 'digital-counter-animate' : ''}`}>
        Num of Aufträge: {displayedCount}
    </p>

    {/* Arrow between the two counters */}
    <FaArrowRight className="arrow" />

    {/* Display the animated Provision count */}
    <p className={`digital-counter ${animateCount ? 'digital-counter-animate' : ''}`}>
        Provision: {money} €
    </p>
</div>

                        {/* Filter Inputs and Sorting */}
                        <div className="filter-card">
                            <div className="filters">
                                <input
                                    type="text"
                                    className="filter-input"
                                    placeholder="Filter by Kunden Name"
                                    value={kundeFilter}
                                    onChange={(e) => setKundeFilter(e.target.value)}
                                />
                                <input
                                    type="text"
                                    className="filter-input"
                                    placeholder="Filter by Nachname"
                                    value={nachnameFilter}
                                    onChange={(e) => setNachnameFilter(e.target.value)}
                                />
                                <input
                                    type="month"
                                    className="filter-input"
                                    value={monthFilter}
                                    onChange={(e) => setMonthFilter(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='table-wrapper1'>
                            <table className="antrags-table">
                                <thead>
                                    <tr>
                                        <th>Mobile</th>
                                        <th>Name</th>
                                        <th>Nachname</th>
                                        <th>start datum</th>
                                        <th>Kinder</th>
                                        <th>Kinder +15</th>
                                        <th>Geburtstag</th>
                                        <th onClick={() => toggleSortOrder('submite_date')}>
                                            Sent Date {sortField === 'submite_date' && (sortOrder === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th>Firma</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedClients.map((client) => {
                                        const { totalChildren, childrenOver15 } = getChildInfo(client);
                                        return (
                                            <tr key={client.id} onClick={() => navigate(`/client-details/${client.id}`)}>
                                                <td>{client.number}</td>
                                                <td>{client.vornname}</td>
                                                <td>{client.nachname}</td>
                                                <td>{client.start_date ? new Date(client.start_date).toLocaleDateString() : 'N/A'}</td>
                                                <td>{totalChildren}</td>
                                                <td>{childrenOver15}</td>
                                                <td>{client.geburtstage ? new Date(client.geburtstage).toLocaleDateString() : 'N/A'}</td>
                                                <td>{client.submite_date ? new Date(client.submite_date).toLocaleDateString() : 'N/A'}</td>
                                                <td>{client.firma}</td>
                                                <td>New</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllClients;
