// src/services/api.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_URL1 = process.env.REACT_APP_API1_URL;

const TICKET_URL = process.env.REACT_APP_TICKET_URL;
const TICKET_F_URL = process.env.REACT_APP_TICKET_F_URL;
const ANTRAG_URL = process.env.REACT_APP_ANTRAG_URL;
const AUTH_URL = process.env.REACT_APP_AUTH_URL; // New API URL for login
const PROFILE_PIC_URL = process.env.REACT_APP_PROFILE_PIC_URL; // URL for profile picture upload

const api = axios.create();



api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        const tokenExpiry = localStorage.getItem('tokenExpiry');
        
        if (tokenExpiry && new Date() > new Date(tokenExpiry)) {
            // Token is expired, log out the user
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiry');
            window.location.href = '/login'; // Redirect to login page
            return Promise.reject(new Error('Token expired'));
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


const register = async (username, password) => {
    const response = await api.post(`${API_URL}/register`, { username, password });
    return response.data;
};

export { register };

// Example usage for login
// Example usage for login
export const login = async (username, password) => {
    const response = await axios.post(AUTH_URL, { username, password });
    
    const token = response.data.token;
    const expiryTime = new Date(new Date().getTime() + 600 * 60 * 1000); // 1 hour from now
    localStorage.setItem('role', response.data.user.role); // Save the role in localStorage
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiry', expiryTime);
    localStorage.setItem('user_id', response.data.user.id); // Save the current user's ID
    
    return response.data;
};

export const emailAouth = async () => {
    const token = localStorage.getItem('token');
    return await api.get(`${API_URL}/auth`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const uploadFile = async (ticketId, file) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', file);
    return await api.post(`${TICKET_F_URL}/${ticketId}/upload`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const getTickets = async () => {
    const token = localStorage.getItem('token');
    return await api.get(TICKET_URL, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const addTicket = async (ticket) => {
    const token = localStorage.getItem('token');
    return await api.post(TICKET_URL, ticket, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const deleteTicket = async (id) => {
    const token = localStorage.getItem('token');
    return await api.delete(`${TICKET_URL}/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

// Add a function to post a reply
export const addReply = async (ticketId, formData) => {
    const token = localStorage.getItem('token');
    return await api.post(`${TICKET_URL}/${ticketId}/replies`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data', // Specify that it's form data
        }
    });
};


// Update Ticket Status
export const updateTicketStatus = async (ticketId, status) => {
    const token = localStorage.getItem('token');
    return await api.put(`${TICKET_URL}/${ticketId}/status`, { status }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};




export const getTicketDetails = async (ticketId) => {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('No authorization token found.');
    }

    try {
        const response = await api.get(`${TICKET_URL}/${ticketId}/details`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Check if the response is valid and has data
        if (response.status !== 200) {
            throw new Error('Failed to fetch ticket details: ' + response.statusText);
        }
         console.log(response.data);
        return response.data;
    } catch (error) {
        // Handle specific error response
        if (error.response) {
            // The request was made, but the server responded with a status code
            throw new Error('Failed to fetch ticket details: ' + error.response.data.message || error.response.statusText);
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error('No response received from server.');
        } else {
            // Something happened in setting up the request
            throw new Error('Error in setting up request: ' + error.message);
        }
    }
};

export const uploadProfilePic = async (formData) => {
    const token = localStorage.getItem('token'); // Get the stored token
    return await api.put(PROFILE_PIC_URL, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    });
};

// ** ANTRAG FUNCTIONS **

// Get all Antrags
export const getEmpAntrags = async () => {
    const token = localStorage.getItem('token');
    return await api.get(ANTRAG_URL, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};
export const getAllAntrags = async () => {
    const token = localStorage.getItem('token');
    return await api.get(`${ANTRAG_URL}/All`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

// Create a new Antrag
export const createAntrag = async (antragData) => {
    const token = localStorage.getItem('token');
    return await api.post(ANTRAG_URL, antragData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const addClient=async(client)=>{
    const token = localStorage.getItem('token');
    return await api.post(`${ANTRAG_URL}/create-customer`, client, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

// Update an existing Antrag
export const updateAntrag = async (id, antragData) => {
    const token = localStorage.getItem('token');
    return await api.put(`${ANTRAG_URL}/${id}`, antragData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

// Delete an Antrag
export const deleteAntrag = async (id) => {
    const token = localStorage.getItem('token');
    return await api.delete(`${ANTRAG_URL}/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const getAntragsByEmployeeId = async (employeeId) => {
    const response = await api.get(`${API_URL}/employee/${employeeId}`);
    return response.data;
};

// Get all Employees
export const getAllEmployees = async () => {
    const token = localStorage.getItem('token');
    console.log(token)
    return await api.get(`${API_URL}/employees`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};
// Get all 
export const getAll = async () => {
    const token = localStorage.getItem('token');
    console.log(token)
    return await api.get(`${API_URL}/Admin`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const fillPdf = async (formData) => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.post(
        `${API_URL}/fill-pdf`, 
        formData, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob', // Important for receiving the PDF as a file
        }
      );
      return response.data; // Return the blob (PDF)
    } catch (error) {
      console.error('Error filling PDF:', error);
      throw error;
    }
  };

export const fillBigPdf = async (formData) => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.post(
        `${API_URL1}/BigForm/fill-pdf`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob', // Important for receiving the PDF as a file
        }
      );
      return response.data; // Return the blob (PDF)
    } catch (error) {
      console.error('Error filling PDF:', error);
      throw error;
    }
  };
export const fillBigBonusPdf = async (formData) => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.post(
        `${API_URL1}/BigForm/fill-pdf-bonus`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob', // Important for receiving the PDF as a file
        }
      );
      return response.data; // Return the blob (PDF)
    } catch (error) {
      console.error('Error filling PDF:', error);
      throw error;
    }
  };
export const fillBigFamielienPdf = async (formData) => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.post(
        `${API_URL1}/BigForm/fill-pdf-famielien`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob', // Important for receiving the PDF as a file
        }
      );
      return response.data; // Return the blob (PDF)
    } catch (error) {
      console.error('Error filling PDF:', error);
      throw error;
    }
  };


  // Update user's password (superAdmin only)
export const updateUserPassword = async (userId, newPassword) => {
    const token = localStorage.getItem('token');
    const response = await api.put(
        `${API_URL}/users/${userId}/password`,
        { newPassword },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data;
};

export const searchClientByFirstName = async (name) => {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get(`${API_URL}/search-client`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                name, // Pass the name as a query parameter
            },
        });
        return response.data; // Return client data if found
    } catch (error) {
        console.error('Error searching client:', error);
        throw error; // Propagate the error for further handling
    }
};

export const fetchClientsByEmployeeId = async (employeeId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`/api/clients/employee/${employeeId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data; // Return client data if found
    } catch (error) {
        console.error('Error fetching clients:', error);
        throw error; // Propagate the error for further handling
    }
};


// In your api.js or appropriate service file
export const checkClientExistence = async (name, geburtstag, employeeId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get(`${API_URL}/check-client`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                name,
                geburtstag,
                employee_id: employeeId, // Add employee ID to params
            },
        });
        return response.data; // Returns true or client data if client exists
    } catch (error) {
        console.error('Error checking client existence:', error);
        throw error; // Propagate error for handling
    }
};

export const getClientsByEmployeeId = async (employeeId) => {
    const token = localStorage.getItem('token');
    return api.get(`${API_URL}/clients/by-employee/${employeeId}`,{
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getClientsForall = async () => {
    const token = localStorage.getItem('token');
    return api.get(`${API_URL}/All-clients`,{
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getClientDetails = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get(`${ANTRAG_URL}/client/${id}`,  {headers: {
        Authorization: `Bearer ${token}`,
    },}); // Adjust the base URL if necessary
      return response;
    } catch (error) {
      console.error("Error fetching client details:", error);
      throw error;
    }
  };

  export const fillBigSecondPdf=async(formData)=>{
    const token = localStorage.getItem('token');
    try {
      const response = await api.post(
        `${API_URL1}/BigForm/fill-pdf-famielien2`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob', // Important for receiving the PDF as a file
        }
      );
      return response.data; // Return the blob (PDF)
    } catch (error) {
      console.error('Error filling PDF:', error);
      throw error;
    }
  }