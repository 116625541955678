import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getClientDetails } from '../../services/api';
import { FaFilePdf, FaPlus, FaMinus } from 'react-icons/fa';
import './clients.css';

const ClientDetails = () => {
  const { id } = useParams();
  const [clientData, setClientData] = useState(null);
  const [showFiles, setShowFiles] = useState(false);

  const toggleFiles = () => setShowFiles(!showFiles);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await getClientDetails(id);
        setClientData(response.data);
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };
    fetchClientData();
  }, [id]);

  let filesArray = [];
  if (clientData) {
    filesArray = Array.isArray(clientData.files)
      ? clientData.files
      : JSON.parse(clientData.files || '[]'); // Handle undefined or null files gracefully
  }



  if (!clientData) return <p>Loading client details...</p>;

  return (
    <div className="client-details magical-bg">
      <div className="client-info magical-card">
        <h2>Client Details</h2>
        <div className='sec-det'><p><strong>Name:</strong></p> <p>{clientData.vornname}</p></div>
        <div className='sec-det'><p><strong>Nachname:</strong></p><p>{clientData.nachname}</p></div>
        <div className='sec-det'><p><strong>Geburtstag:</strong></p><p>{new Date(clientData.geburtstage).toLocaleDateString()}</p></div>
        <div className='sec-det'><p><strong>mobile:</strong></p> <p>{clientData.number}</p></div>
        <div className='sec-det'><p><strong>Kinder Total:</strong></p> <p>{clientData.kindnum}</p></div>

        {clientData.kindnum > 0 && (
          <div className='sec-det'>
            <p><strong>Kinder details:</strong></p>
            {clientData.children.map((child, index) => (<div>
              <p key={index}>{child.child_vorname}</p>
              <p key={index}>{child.child_nachname}</p>
              <p key={index}>{child.birthdate}</p></div>
            ))}
          </div>
        )}
      </div>

      {filesArray.length > 0 && (
        <div className="files-section magical-card">
          <div className="files-header" onClick={toggleFiles}>
            <h3>Available Files</h3>
            {showFiles ? <FaMinus className="toggle-icon" /> : <FaPlus className="toggle-icon" />}
          </div>
          {showFiles && (
            <div className="files-container">
              {filesArray.map((file, index) => {
                const fileName = file.split('antrags')[1] || `file-${index}`;

                return (
                  <a
                key={index} // Apply key to the outer <a> element
                href={`https://xn--kunde-sham-direkt-antrge-ccc.de:3000/api/tickets/download/${file.replace(/\\/g, '/')}`}
                download={fileName || `file-${index}`} // Fallback in case fileName is undefined
                className="download-button"
              >
                  
                    <div className="file-item">
                      <FaFilePdf className="file-icon" />
                      <span className="file-name">{fileName}</span>
                    </div>
                  </a>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClientDetails;
